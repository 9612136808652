/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Portada from "./Componentes/Portada";
import ColeccionHome from "./Componentes/ColeccionHome";
import DisenadoraHome from "./Componentes/DisenadoraHome";
import TipoTela from "./Componentes/TipoTela";
import FraseHumanizadora from "./Componentes/FraseHumanizadora";
import TrajesFormales from "./Componentes/TrajesFormales";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import InspiracionesHome from "./Componentes/InspiracionesHome";
import {useContext, useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";
import {configContext} from "../../App";
import {useReciboPago} from "../../Modulo_Pasarela_Pagos/Hooks/useReciboPago";
import logo from '../../Recursos/logo_negro.png'

const Home = () => {
    const {sCell} = useResponsive()
    const cData = useContext(configContext)
    const {Dialogo} = useReciboPago({logo: logo})

    useEffect(() => {

        irArriba()


    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Portada/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 6 : 12}}>
                <ColeccionHome coleccion={cData.configuraciones.coleccion}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 8}}>
                <InspiracionesHome coleccion={cData.configuraciones.coleccion}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 12}}>
                <DisenadoraHome/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 12}}>
                <TipoTela/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? -6 : 4}}>
                <FraseHumanizadora/>
            </Grid>

            <Grid id={'trajes'} item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? -4 : 6}}>
                <TrajesFormales/>
            </Grid>


        </Grid>
    )

}
export default Home    