/**************************************************
 * Nombre:       Datos_Pago
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {formatoMoneda} from "../../../../Utilidades/formatoMoneda";

const Datos_Pago = ({item}) => {

    const TituloTexto = ({titulo, texto}) => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >

                <Grid item container lg={3} sm={3} xs={4} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontSize: 16, fontWeight: 400}}>{titulo}</Typography>
                </Grid>

                <Grid item container lg={8} sm={9} xs={8} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontSize: 16, fontWeight: 400}}>{texto}</Typography>
                </Grid>

            </Grid>
        )
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloTexto titulo={'Name:'} texto={item.nombre}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloTexto titulo={'Description:'} texto={item.descripcion}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloTexto titulo={'Value:'} texto={`${formatoMoneda(item.valor, 2)} USD`}/>
            </Grid>

        </Grid>
    )

}
export default Datos_Pago