/**************************************************
 * Nombre:       Dialogo_Recibo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import {PictureAsPdf} from "@mui/icons-material";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Recibo from "../Pdfs/Pdf_Recibo";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";

const Dialogo_Recibo = ({logo, datos, referencia}) => {
    const [pedido, setPedido] = useState('')

    const obtenerMensaje = (estado) => {

        switch (estado) {
            case 1:
                return {
                    titulo: 'Thank you for your purchase',
                    mensaje: 'We will be shipping your order soon, we will contact you as soon as possible \n' +
                        'Remember to download the receipt of your purchase.'
                }
            case 2:
                return {
                    titulo: 'Your payment was rejected',
                    mensaje: 'Don\'t worry, it must be due to lack of balance on your card or payment method, check your balances and try again'
                }
            case 3:
                return {
                    titulo: 'Pending transaction',
                    mensaje: 'The transaction is pending approval, your payment method needs you to approve this purchase, check your email. If you paid by PSE it takes up to 20 minutes to complete the transaction. Don\'t worry, the administrator has already been notified of the purchase and will be attentive when the payment is made. Download your buyer.'
                }
            case 4:
                return {
                    titulo: 'Failed transaction',
                    mensaje: 'The transaction creation flow was not completed successfully. Check your payment method and try again.'
                }
            case 11:
                return {
                    titulo: 'Transaction Aborted',
                    mensaje: 'The purchase process has not been completed, do not worry, nothing has been charged to your payment method. Don\'t miss this opportunity to bring an excellent product to your home, try again'
                }
            case 15:
                return {
                    titulo: 'Transaction ready to be approved',
                    mensaje: 'The transaction is taking place, give us a few minutes so it can be verified by the administrator'
                }

            default :
                return {
                    titulo: 'T',
                    mensaje: 'L'
                }
        }
    }


    useEffect(() => {
        let id = datos.x_extra1
        console.log(datos)

        let datosNuevos = {
            pagado: datos.x_amount_ok,
            numeroAprobacion: datos.x_approval_code,
            correoPago: datos.x_customer_email,
            fechaPago: datos.x_fecha_transaccion,
            referenciaEpayco: datos.x_ref_payco,
            estadoPago: datos.x_response,
            referencia: referencia,
            nombreBanco: datos.x_bank_name
        }

        if (datos.x_extra2 && datos.x_extra2 === 'Cobro') {


            obtenerDoc('cobros', id).then((cob) => {
                if (cob.res) {

                    let cobro = cob.data
                    datosNuevos.productos = [{
                        cantidad: 1,
                        nombre: 'Pay # ' + cobro.consecutivo + ' ' + cobro.descripcion,
                        valorFinal: cobro.valor
                    }]

                    actualizarDoc('cobros', id, datosNuevos).then((dox) => {
                        if (dox.res) {
                            obtenerDoc('cobros', id).then((dox) => {
                                console.log(dox)
                                if (dox.res) {
                                    setPedido(dox.data)

                                }
                            })
                        } else {

                        }
                    })

                }

            })


        } else {
            actualizarDoc('pedidos', id, datosNuevos).then((dox) => {
                if (dox.res) {
                    obtenerDoc('pedidos', id).then((dox) => {
                        if (dox.res) {
                            setPedido(dox.data)

                        }
                    })
                } else {

                }
            })
        }


    }, [datos]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <img src={logo} width={150} height={'auto'}/>
            </Grid>

            <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Typography
                    sx={{
                        fontSize: 24,
                        fontWeight: 800,
                        textAlign: 'center'
                    }}>{datos.x_cod_response && obtenerMensaje(datos.x_cod_response).titulo}</Typography>
            </Grid>

            <Grid item container lg={10} sm={10} xs={10} sx={{justifyContent: 'center', marginTop: 2}}>
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        textAlign: 'center'
                    }}>{datos.x_cod_response && obtenerMensaje(datos.x_cod_response).mensaje}</Typography>
            </Grid>

            {pedido ?

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'center', marginTop: 4, marginBottom: 4}}>

                    <PDFDownloadLink document={<Pdf_Recibo logo={logo} datos={datos} pedido={pedido}/>}
                                     fileName={"Purchase receipt " + pedido.id}
                                     style={{textDecoration: "none"}}>
                        <Button
                            color={'primary'}
                            startIcon={<PictureAsPdf/>}
                        >
                            Download Receipt</Button>
                    </PDFDownloadLink>

                </Grid>

                :

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <CircularProgress/>
                </Grid>


            }

        </Grid>
    )

}
export default Dialogo_Recibo    