import React, {createContext, useEffect, useState} from 'react';
import './App.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import {Grid} from "@mui/material";
import Pagina from "./Pagina/Pagina";
import {obtenerDoc} from "./Servicios/BD/obtenerDoc";

export const configContext = createContext('')

function App() {
    const [configuraciones, setConfiguraciones] = useState({})

    const valoresProvider = {
        configuraciones,
    }

    const {Provider} = configContext;

    useEffect(() => {
        obtenerDoc('configuraciones', 'home').then((dox) => {
            if (dox.res) {
                setConfiguraciones(dox.data)
            }
        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Provider value={valoresProvider}>
                <Pagina/>
            </Provider>

        </Grid>
    );
}

export default App;
