/**************************************************
 * Nombre:       FraseHumanizadora
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const FraseHumanizadora = ({color = '#000'}) => {
    const {sCell} = useResponsive()


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 16}}>
                            <Typography sx={{fontSize: sCell ? 14 : 18, color: color, fontWeight: 400,}}>
                                Dress with Purpose
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 0}}>
                            <Typography sx={{
                                fontSize: sCell ? 28 : 42,
                                color: color,
                                fontWeight: 700,
                                textAlign: 'center',
                                lineHeight: 1.1

                            }}>A Green Future in Every Thread</Typography>
                        </Grid>

                        <Grid item container lg={5} sm={8} xs={10}
                              sx={{justifyContent: 'flex-end', marginTop: 2, marginBottom: 14}}>
                            <Typography sx={{fontSize: 14, color: color, fontWeight: 400, textAlign: 'center'}}>
                                Every garment you choose responsibly is one more thread in the fabric of a greener
                                future.
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default FraseHumanizadora