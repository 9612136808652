/**************************************************
 * Nombre:       Tarjeta_Inspiracion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import fondo from '../../../Recursos/fondo_trajes.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {irUrl} from "../../../Utilidades/irUrl";
import {LINKCALENDY} from "../../../Constantes";

const TrajesFormales = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                    maxWidth: '1400px',
                    mx: 3,
                    backgroundImage: `linear-gradient(179.55deg, rgba(0, 0, 0, 0) 20.45%, #000000 99.35%),
                  url(${fondo});`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: sCell ? 'right' : 'center',
                    borderTopLeftRadius: 60,
                    borderBottomRightRadius: 60,
                    p: 6
                }}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                    <Typography sx={{fontSize: sCell ? 14 : 18, color: '#fff', fontWeight: 400,}}>
                        Custom Suits
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : -1}}>
                    <Typography sx={{
                        fontSize: sCell ? 24 : 42,
                        color: '#fff',
                        fontWeight: 700,

                    }}>
                       Custom Suits For Every Occasion</Typography>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography sx={{fontSize: sCell ? 14 : 14, color: '#fff', fontWeight: 400,}}>
                        Discover excellence in design with our bespoke formal suit service, where each garment is a
                        personalized work of art that combines high-end tailoring techniques with elegant and
                        sophisticated style, ensuring that every detail reflects your individuality and perfectly suits
                        your body and your needs.
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <Button
                        onClick={() => irUrl(LINKCALENDY)}
                        sx={{px: sCell ? 4 : 6, fontSize: sCell ? 14 : 16, py: sCell ? 0.8 : 1}}>SCHEDUEL APPOINTMENT</Button>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default TrajesFormales