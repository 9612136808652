/**************************************************
 * Nombre:       Pdf_Recibo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Document, Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import f500 from '../Recursos/Montserrat-Regular.ttf'
import f600 from '../Recursos/Montserrat-Medium.ttf'
import f700 from '../Recursos/Montserrat-Bold.ttf'
import {formatoMoneda} from "../Utilidades/formatoMoneda";

const Pdf_Recibo = ({logo, pedido, datos}) => {
    Font.register({family: '500', src: f500})
    Font.register({family: '600', src: f600})
    Font.register({family: '700', src: f700})


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFF',
            padding: 28
        },
        gitc: {
            justifyContent: 'center',
            flexDirection: 'row',
            width: '100%'
        },
        git: {
            justifyContent: 'flex-start',
            flexDirection: 'row',
            width: '100%'
        },
        gitg: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            width: '100%'
        },
        texto: {
            fontSize: 8,
            fontFamily: '600'
        },
        textoL: {
            fontSize: 8,
            fontFamily: '500'
        },
        textoGT: {
            fontSize: 10,
            fontFamily: '700'
        },
        textoG: {
            fontSize: 10,
            fontFamily: '500'
        },
        textoPq: {
            fontSize: 7,
            fontFamily: '500'
        },


    });

    const Titulos = ({derecha, izquierda}) => {
        return (
            <View style={styles.gitc}>
                <View style={{...styles.git, width: '50%'}}>
                    <Text style={styles.textoGT}>{derecha}</Text>
                </View>
                <View style={{...styles.git, width: '50%', justifyContent: 'flex-end'}}>
                    <Text style={styles.textoGT}>{izquierda}</Text>
                </View>
            </View>
        )
    }

    const Datos = ({derecha, izquierda}) => {
        return (
            <View style={styles.gitc}>
                <View style={{...styles.git, width: '50%'}}>
                    <Text style={styles.textoG}>{derecha}</Text>
                </View>
                <View style={{...styles.git, width: '50%', justifyContent: 'flex-end'}}>
                    <Text style={styles.textoG}>{izquierda}</Text>
                </View>
            </View>
        )
    }

    const Items = ({items = []}) => {

        return items.map((it, index) => {
            return (
                <View style={styles.gitc}>
                    <View style={{...styles.git, width: '50%'}}>
                        <Text style={styles.textoG}>{`( ${it.cantidad} ) ${it.nombre}`}</Text>
                    </View>
                    <View style={{...styles.git, width: '50%', justifyContent: 'flex-end'}}>
                        <Text style={styles.textoG}>{`${formatoMoneda(it.cantidad * it.valorFinal, 2)} USD`}</Text>
                    </View>
                </View>
            )
        })
    }

    const obtenerTotal = (items) => {

        let tot = 0
        for (let i = 0; i < items.length; i++) {
            let val = items[i].cantidad * items[i].valorFinal
            tot += val
        }

        return tot
    }

    return (
        <Document>
            <Page size={'C6'} style={styles.page}>
                <View style={{justifyContent: 'flex-start', flexDirection: 'column', width: '100%'}}>
                    <View style={{...styles.gitc, marginTop: 10}}>
                        <Image src={logo} style={{width: 160, height: 24}}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 30}}>
                        <Text style={styles.textoG}>Bogota D.C - Colombia</Text>
                    </View>
                    <View style={{...styles.gitc, marginTop: 0}}>
                        <Text style={styles.textoG}>pedidos@sarasty.com</Text>
                    </View>

                    <View style={{...styles.gitc, marginTop: 4}}>
                        <Text style={styles.textoG}>+1 (347) 791-2273</Text>
                    </View>

                    <View style={{...styles.git, marginTop: 20}}>
                        <Datos derecha={'Payment Date'} izquierda={datos.x_fecha_transaccion}/>
                    </View>

                    <View style={{...styles.git, marginTop: 10}}>
                        <Titulos derecha={'Ref Payment'} izquierda={datos.x_ref_payco}/>
                    </View>

                    <View style={styles.git}>
                        <Titulos derecha={'State Payment'} izquierda={datos.x_response}/>
                    </View>

                    <View style={styles.git}>
                        <Datos derecha={'Bank'} izquierda={datos.x_bank_name}/>
                    </View>


                    {datos.x_ref_payco === 'CRIPTO' ?


                        <>

                            <View style={styles.git}>
                                <Datos derecha={'Approval Number'} izquierda={''}/>
                            </View>

                            <View style={styles.git}>
                                <Text style={styles.textoPq}>{datos.x_approval_code}</Text>
                            </View>

                        </>

                        :

                        <View style={styles.git}>
                            <Datos derecha={'Approval Number'} izquierda={datos.x_approval_code}/>
                        </View>


                    }


                    <View style={styles.git}>
                        <Datos derecha={'Id Order'} izquierda={pedido.id}/>
                    </View>


                    <View style={{...styles.gitc, marginTop: 20}}>
                        <Text
                            style={styles.textoG}>*******************************************************************</Text>
                    </View>


                    <View style={styles.git}>
                        <Titulos derecha={'Description'} izquierda={'Value'}/>
                    </View>

                    <View style={{...styles.git, flexDirection: 'column'}}>
                        <Items items={pedido.productos}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 6}}>
                        <Text
                            style={styles.textoG}>*******************************************************************</Text>
                    </View>

                    <View style={styles.git}>
                        <Titulos derecha={'Total Paid'}
                                 izquierda={`${formatoMoneda(obtenerTotal(pedido.productos), 2)} USD`}/>
                    </View>

                    <View style={{...styles.gitc, marginTop: 40}}>
                        <Text style={styles.textoG}>Thank you very much for your purchase!</Text>
                    </View>


                </View>
            </Page>
        </Document>
    )

}
export default Pdf_Recibo    