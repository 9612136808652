/**************************************************
 * Nombre:       Tarjeta_Noticias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {irUrl} from "../../Utilidades/irUrl";

const Tarjeta_Noticias = ({item}) => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{borderBottom: 1, px: 2, py: 8}}
            spacing={2}
        >

            <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'center'}}>
                <img src={item.img} width={'70%'} height={'auto'}/>
            </Grid>

            <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 5 : 0}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                        <Typography sx={{fontSize: sCell ? 14 : 18, color: '#000', fontWeight: 400,}}>
                            {item.subtitulo}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                        <Typography sx={{
                            fontSize: sCell ? 24 : 38,
                            color: '#000',
                            fontWeight: 700,
                            lineHeight: 1.1

                        }}>{item.nombre}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-end', marginTop: 2, marginBottom: 0}}>
                        <Typography sx={{fontSize: 14, color: '#000', fontWeight: 400, textAlign: 'left'}}>

                            {item.descripcion}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        <Button
                            onClick={() => irUrl(item.link)}
                            color={'primary'}>See More</Button>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Noticias