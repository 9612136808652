/**************************************************
 * Nombre:       Seccion_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import Portada_Detalle from "./Componentes/Portada_Detalle";
import ColeccionHome from "./Componentes/ColeccionHome";
import TipoTela from "./Componentes/TipoTela";
import FraseHumanizadora from "./Componentes/FraseHumanizadora";
import TrajesFormales from "./Componentes/TrajesFormales";
import {useParams} from "react-router-dom";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {irArriba} from "../../Utilidades/irArriba";
import {useFuncionesCarrito} from "../../Modulo_carrito_compras/Hooks/useFuncionesCarrito";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";

const Seccion_Detalle = () => {
    const {sCell, masSM} = useResponsive()
    const parms = useParams()
    const [producto, setProducto] = useState({})
    const [recursos, setRecursos] = useState([])


    const obtenerRecursos = (prod) => {

        let videos = prod.videos && prod.videos ? prod.videos : []

        let arr = []
        arr.push({tipo: 'img', src: prod.img})
        arr.push({tipo: 'img', src: prod.imgAtras})

        let imagenes = prod.imgs ? prod.imgs : []

        for (let i = 0; i < imagenes.length; i++) {
            arr.push({tipo: 'img', src: imagenes[i]})
        }

        for (let i = 0; i < videos.length; i++) {
            arr.push({tipo: 'video', src: videos[i]})
        }


        setRecursos(arr)

    }

    useEffect(() => {

        irArriba()

        if (parms && parms.id) {
            let id = parms.id
            obtenerDoc('productos', id).then((dox) => {
                if (dox.res) {
                    setProducto(dox.data)
                    obtenerRecursos(dox.data)
                }
            })
        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >



            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, marginTop: masSM ? 24 : 14}}
            >

                <Grid item container lg={12} sm={12} xs={11} sx={{justifyContent: 'center'}}>
                    <Portada_Detalle item={producto} recursos={recursos}/>
                </Grid>

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 12}}>
                    <ColeccionHome coleccion={producto.coleccion} difiere={true}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 14}}>
                    <TipoTela/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                    <FraseHumanizadora/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <TrajesFormales/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Seccion_Detalle

