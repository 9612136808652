/**************************************************
 * Nombre:       Tarjeta_Anuncios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {TaskAlt} from "@mui/icons-material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {irUrl} from "../../Utilidades/irUrl";

const Tarjeta_Anuncios = ({item}) => {
    const {sCell} = useResponsive()
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxHeight: 350, py: 4}}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Typography
                    sx={{
                        color: '#fff',
                        fontSize: sCell ? 14 : 24,
                        fontWeight: 400,
                        textAlign: 'flex-start'
                    }}>
                    {item.subtitulo}
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Typography
                    sx={{
                        color: '#fff',
                        fontSize: sCell ? 24 : 52,
                        fontWeight: 700,
                        textAlign: 'flex-start',
                        lineHeight: 1.1
                    }}>
                    {item.nombre && item.nombre.toUpperCase()}
                </Typography>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent:  'flex-start', marginTop: sCell ? 2 : 4}}>
                <Button
                    onClick={() => irUrl(item.link)}
                    sx={{px: sCell ? 6 : 8, py: sCell ? 0.5 : 2, fontSize: sCell ? 14 : 16}}>
                    see more
                </Button>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Anuncios