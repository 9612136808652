/**************************************************
 * Nombre:       Tarjeta_tienda
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import fondo from "../../Temporales/piedra.png";

const Tarjeta_tienda = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.40) 25%, rgba(0, 0, 0, 0.40) 25%), url(${item.img});`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                py: 5,
                px: 2,
                border: 4,
                borderColor: '#fff',
                height: 280
            }}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Typography
                    sx={{fontSize: 16, fontWeight: 700, color: '#fff', textAlign: 'center'}}>{item.nombre}</Typography>
            </Grid>

        </Grid>
    )

}
export default Tarjeta_tienda