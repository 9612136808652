/**************************************************
 * Nombre:       ColeccionHome
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import ListaProductos from "./SubComponentes/ListaProductos";
import {useEffect, useState} from "react";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../../fire";
import {obtenerColeccionQuery} from "../../../Servicios/BD/obtenerColeccionQuery";

const Coleccion_Portada = ({coleccion}) => {
    const [productos, setProductos] = useState([])

    useEffect(() => {
        if (coleccion && coleccion.nombre) {
            let q = query(collection(fire, 'productos'), where('coleccion', '==', coleccion.nombre))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res) {
                    setProductos(dox.data)
                }
            })
        }
    }, [coleccion]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={11} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                    <ListaProductos items={productos}/>
                </Grid>


            </Grid>

        </Grid>
    )

}
export default Coleccion_Portada