/**************************************************
 * Nombre:       ValoresTotales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, CircularProgress, Divider, Grid, Typography} from "@mui/material";
import epayco from '../Recursos/epayco.png'
import {useEffect, useState} from "react";
import {useCantidadesProducto} from "../Hooks/useCantidadesProducto";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import BotonPagos from "../../Modulo_Pasarela_Pagos/Componentes/BotonPagos";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {adaptador_pedido} from "../Adaptadores/adaptador_pedido";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Anuncio from "../../Pagina/Formularios/Formulario_Anuncio";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {LoadingButton} from "@mui/lab";

const ValoresTotales = ({obtenerEntidad, idPedido, setIdPedido}) => {
    const {valorTotal, cantidadesProducto} = useCantidadesProducto()
    const [total, setTotal] = useState(0)
    const [descuentos, setDescuentos] = useState([])
    const [guardando, setGuardando] = useState(false)
    const {props, obtenerEntidad: obtenerEtidadDescuento} = useFormulario({valoresDefecto: {}})
    const [cargando, setCargando] = useState(false)
    const [aplicado, setAplicado] = useState(false)

    const aplicarDescuento = () => {
        obtenerEtidadDescuento().then((entidad) => {
            setCargando(true)

            let valorDescuento = obtenerDescueto(entidad)
            if (valorDescuento) {
                let descuento = (total * valorDescuento) / 100
                setCargando(false)
                setAplicado(true)
                let valorNuevo = total - descuento
                setTotal(valorNuevo)
                alert('Discount applied')
            } else {
                alert('Discount code not found')
                setCargando(false)
            }


        })
    }

    const obtenerDescueto = (entidad) => {
        let index = descuentos.findIndex((e) => e.nombre === entidad.nombre)
        if (index > -1) {
            return descuentos[index].valor
        } else {
            return null
        }
    }


    useEffect(() => {
        setGuardando(true)
        setTimeout(() => {
            setGuardando(false)
        }, 1500)
    }, [total]);

    useEffect(() => {

        setGuardando(true)
        obtenerEntidad().then((entidad) => {
            let total_ = valorTotal
            setTotal(total_)
            let pedido = adaptador_pedido({productos: cantidadesProducto, datos: entidad})
            if (idPedido !== '') {
                pedido.id = idPedido
            }
            pedido.estadoPago = 'Abandonado'
            pedido.estado = 'nuevo'

            guardarDoc('pedidos', pedido).then((dox) => {
                if (dox.res) {
                    setGuardando(false)
                    setIdPedido(dox.data)
                } else {
                    setGuardando(false)
                }

            })


        })

        obtenerCol('descuentos').then((dox) => {
            if (dox.res) {
                setDescuentos(dox.data)
            }
        })


    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 0, pt: 2, pb: 0}}
        >

            <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'center'}}>
                <Typography sx={{color: '#000000', fontSize: 16, fontWeight: 600}}>SHIPPING UPDATE</Typography>
            </Grid>

            <Grid item container lg={11} sm={9} xs={9} sx={{justifyContent: 'center', marginTop: 1}}>
                <Typography sx={{color: '#000000', fontSize: 12, fontWeight: 400, textAlign: 'center'}}>Customs and
                    Duties Charges: Almost
                    all shipments crossing international borders (outside the Colombia) are subject to the assessment of
                    duties and taxes imposed by the importing country's government. *Sale items are final & cannot be
                    returned</Typography>
            </Grid>

            <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{color: '#000000', fontSize: 16}}>
                            Product value</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                        <Typography sx={{
                            color: '#000000',
                            fontWeight: 600,
                            fontSize: 16
                        }}>{`${formatoMoneda(valorTotal, 2)} USD`}</Typography>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 1, marginBottom: 1}}>
                        <Divider sx={{width: '100%'}}/>
                    </Grid>


                </Grid>
            </Grid>

            {descuentos.length > 0 &&
                <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                            <Formulario_Anuncio props={props}/>
                        </Grid>

                        <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-start'}}>
                            <LoadingButton
                                disabled={aplicado}
                                onClick={() => aplicarDescuento()}
                                loading={cargando} color={'primary'}
                                variant={'outlined'}>Apply</LoadingButton>
                        </Grid>

                    </Grid>
                </Grid>
            }


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 6}}>
                {guardando ?

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography>
                                Generating Order
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                            <CircularProgress/>
                        </Grid>

                    </Grid>
                    :
                    <BotonPagos valor={total} idPedido={idPedido}/>
                }

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <img src={epayco} width={'100%'} height={'auto'}/>
            </Grid>


        </Grid>
    )

}
export default ValoresTotales