import {doc, onSnapshot} from "firebase/firestore";
import {fire} from "../../../../fire";
import {useEffect, useState} from "react";

export const useEscucharDoc = ({coleccion, id}) => {
    const [data, setData] = useState('')



    useEffect(() => {
        if (id){
            let documento = doc(fire, coleccion, id)


            onSnapshot(documento, (dox) => {
                console.log(`Received doc snapshot: ${dox}`);
                setData(dox.data())
            })


        }
    }, [id]);
    return {
        data
    }
}