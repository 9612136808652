/**************************************************
 * Nombre:       InspiracionesHome
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Tarjeta_Inspiracion from "../../Tarjetas/Tarjeta_Inspiracion";
import {useEffect, useState} from "react";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../../fire";
import {obtenerColeccionQuery} from "../../../Servicios/BD/obtenerColeccionQuery";

const InspiracionesHome = ({coleccion}) => {
    const [coleccionFire, setColeccionFire] = useState([])


    useEffect(() => {
        if (coleccion) {
            let q = query(collection(fire, 'colecciones'), where('nombre', '==', coleccion))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res && dox.data && dox.data[0]) {
                    setColeccionFire(dox.data[0])
                }
            })
        }
    }, [coleccion]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}

            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 10}}>
                    <Tarjeta_Inspiracion item={coleccionFire}/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default InspiracionesHome