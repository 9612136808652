/**************************************************
 * Nombre:       Formulario_Datos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountCircle, AssignmentInd, Email, PhoneAndroid} from "@mui/icons-material";

const Formulario_Datos = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Typography sx={{color: '#00000080', fontSize: 14}}>
                        Personal data</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'First name'} dato={'nombre'} {...props}
                                  requerido={'The name is required'}/>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={AssignmentInd} nombre={'Last Name'} dato={'apellido'} {...props}
                                  requerido={'Last name is required'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={PhoneAndroid} nombre={'Cell phone number'} dato={'celular'} {...props}
                                  requerido={'Cell phone number is required'}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Email} nombre={'Email'} dato={'correo'} {...props}
                                  requerido={'Email is required'}
                    />
                </Grid>


            </Grid>
        </form>
    )

}
export default Formulario_Datos