/**************************************************
 * Nombre:       Tarjeta_Inspiracion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate} from "react-router-dom";

const Tarjeta_Inspiracion = ({item = {}, difiere = false}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    maxWidth: '1400px',
                    mx: 3,
                    backgroundImage: `linear-gradient(179.55deg, rgba(0, 0, 0, 0.2) 20.45%, #000000 99.35%),
                  url(${item.img});`,
                    backgroundPosition: sCell ? 'center' : 'left',
                    backgroundRepeat: 'repeat',
                    borderTopLeftRadius: 60,
                    borderBottomRightRadius: 60,
                    px: sCell ? 4 : 6,
                    py: sCell ? 4 : 6
                }}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <Typography sx={{fontSize: sCell ? 12 : 18, color: '#fff', fontWeight: 400, textAlign: 'center'}}>
                        Inspiration
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -1}}>
                    <Typography sx={{
                        fontSize: sCell ? 28 : 42,
                        color: '#fff',
                        fontWeight: 700,
                        textAlign: 'center'
                    }}>{item.nombre && item.nombre.toUpperCase()}</Typography>
                </Grid>

                {item && item.parrafoUno &&
                    <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography
                            sx={{fontSize: sCell ? 12 : 14, color: '#fff', fontWeight: 400, textAlign: 'center'}}>
                            {item.parrafoUno}
                        </Typography>
                    </Grid>
                }


                {item && item.lista &&

                    item.lista.map((it) => {
                        return (
                            <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                <Typography
                                    sx={{
                                        fontSize: sCell ? 12 : 14,
                                        color: '#fff',
                                        fontWeight: 400,
                                        textAlign: 'center'
                                    }}>
                                    {it}
                                </Typography>
                            </Grid>
                        )
                    })


                }


                {item && item.parrafoDos &&
                    <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography
                            sx={{fontSize: sCell ? 12 : 14, color: '#fff', fontWeight: 400, textAlign: 'center'}}>
                            {item.parrafoDos}
                        </Typography>
                    </Grid>
                }


                {difiere &&
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                        <Button onClick={() => navigate('/Catalogue/' + item.nombre)}>
                            Ver coleccion
                        </Button>
                    </Grid>
                }

            </Grid>


        </Grid>
    )

}
export default Tarjeta_Inspiracion