/**************************************************
 * Nombre:       ingresoDatos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Datos from "../Formularios/Formulario_Datos";
import {theme} from "../../Tema";

const IngresoDatos = ({props}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{px: sCell ? 1 : 2}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Formulario_Datos props={props}/>
            </Grid>


        </Grid>
    )

}
export default IngresoDatos