/**************************************************
 * Nombre:       Portada_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Tab, Tabs, Typography} from "@mui/material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useContext, useEffect, useState} from "react";
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import Tarjeta_Producto_Img_Video from "../../Tarjetas/Tarjeta_Producto_Img_Video";
import {configContext} from "../../../App";
import {useFuncionesCarrito} from "../../../Modulo_carrito_compras/Hooks/useFuncionesCarrito";
import {useCarritoCompras} from "../../../Modulo_carrito_compras/Hooks/useCarritoCompras";
import {TaskAlt} from "@mui/icons-material";


const Portada_Detalle = ({item, recursos}) => {
    const {sCell, masSM} = useResponsive()
    const [producto, setProducto] = useState({})
    const [seleccionada, setSeleccionada] = useState('detalles')
    const cData = useContext(configContext)
    const {adicionarProducto, verificarProductoCarrito} = useFuncionesCarrito()
    const {abrir, DialogoCarrito} = useCarritoCompras()

    const getComponente = (sel) => {

        switch (sel) {
            case 'detalles':
                return (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        {item.parrafoUno &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginBottom: 2}}>
                                <Typography sx={{fontSize: 14, fontWeight: 300}}>
                                    {item.parrafoUno}
                                </Typography>
                            </Grid>
                        }

                        {item.lista && item.lista.map((it) => {
                            return (
                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{fontSize: 14, fontWeight: 300}}>
                                        {`• ${it}`}
                                    </Typography>
                                </Grid>
                            )
                        })}

                        {item.parrafoDos &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                <Typography sx={{fontSize: 14, fontWeight: 300}}>
                                    {item.parrafoDos}
                                </Typography>
                            </Grid>
                        }

                        {item.listaDos && item.listaDos.map((it) => {
                            return (
                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Typography sx={{fontSize: 14, fontWeight: 300}}>
                                        {`• ${it}`}
                                    </Typography>
                                </Grid>
                            )
                        })}

                        {item.parrafoTres &&
                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                <Typography sx={{fontSize: 14, fontWeight: 300}}>
                                    {item.parrafoTres}
                                </Typography>
                            </Grid>
                        }


                    </Grid>

                )
            case 'talla':
                return (

                    <Typography sx={{fontSize: 14, fontWeight: 300}}>
                        {cData.configuraciones.textoTallas}
                    </Typography>
                )

        }


    }


    useEffect(() => {
        setProducto(item)
    }, [item]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <DialogoCarrito/>

            <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: 'flex-start',}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={8}
                >

                    <Grid item container lg={5} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Splide
                            aria-label="My Favorite Images" style={{width: '100%'}}
                            options={{
                                autoplay: true,
                                speed: 1500,
                                rewind: true,
                                perPage: 1,
                                arrows: true,
                                pagination: true,
                                width: '100%',
                                drag: 'free',
                                interval: 5000,
                                noDrag: true,

                            }}
                        >

                            {recursos.map((item, index) => {
                                return (
                                    <SplideSlide key={`qe${index}`}>
                                        <Tarjeta_Producto_Img_Video item={item}/>
                                    </SplideSlide>
                                )
                            })}


                        </Splide>

                    </Grid>

                    <Grid item container lg={7} sm={8} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{fontSize: sCell ? 12 : 18, fontWeight: 400}}>
                                    {`Collection ${item.coleccion}`}
                                </Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{fontSize: sCell ? 24 : 36, fontWeight: 700}}>
                                    {item.nombre}
                                </Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{
                                    fontSize: 16,
                                    fontWeight: 400
                                }}>{`${formatoMoneda(item.valor)} USD`}</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}>

                                {item.stock ?

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        <Grid item container lg={11} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start', marginTop: 4}}>

                                            {verificarProductoCarrito(producto) ?

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={2}
                                                >

                                                    <Grid item container lg={6} sm={6} xs={6}
                                                          sx={{justifyContent: 'flex-start'}}>
                                                        <Typography sx={{
                                                            fontSize: 18,
                                                            color: '#00000080',
                                                            fontWeight: 400
                                                        }}>{'The product is already in the shopping cart'}</Typography>
                                                    </Grid>

                                                    <Grid item container lg={6} sm={6} xs={6}
                                                          sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                                        <Button
                                                            variant={'outlined'}
                                                            color={'primary'}
                                                            onClick={() => abrir()}
                                                            startIcon={<TaskAlt/>}
                                                            sx={{px: 3, py: sCell ? 2 : 1.5,}} fullWidth={sCell}>
                                                            Complete the purchase
                                                        </Button>
                                                    </Grid>

                                                </Grid>


                                                :
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    spacing={2}
                                                >


                                                    <Grid item container lg={5} sm={6} xs={12}
                                                          sx={{justifyContent: 'flex-start'}}>
                                                        <Button
                                                            color={'primary'}
                                                            onClick={() => {
                                                                adicionarProducto(producto)
                                                                abrir()
                                                            }}
                                                            sx={{px: 6, py: sCell ? 2 : 1.5}} fullWidth={sCell}>
                                                            Buy Now
                                                        </Button>
                                                    </Grid>


                                                    <Grid item container lg={6} sm={6} xs={12}
                                                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 2 : 0}}>
                                                        <Button
                                                            color={'primary'}
                                                            onClick={() => adicionarProducto(producto)}
                                                            fullWidth={sCell}
                                                            variant={'outlined'}
                                                            sx={{

                                                                px: 5,
                                                                py: sCell ? 2 : 1.3
                                                            }}>
                                                            Add to Cart
                                                        </Button>
                                                    </Grid>


                                                </Grid>

                                            }

                                        </Grid>
                                    </Grid>

                                    :

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start', marginTop: 4}}>
                                        <Button
                                            disabled
                                            color={'primary'}
                                            fullWidth={sCell}
                                            variant={'outlined'}
                                            sx={{

                                                px: 5,
                                                py: sCell ? 2 : 1.3
                                            }}>
                                            Sold out
                                        </Button>
                                    </Grid>


                                }

                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 3}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start'}}>
                                        <Tabs sx={{color: '#000'}} value={seleccionada}
                                              onChange={(e, value) => setSeleccionada(value)}>
                                            <Tab value={'detalles'} label={'PRODUCT DETAILS'} sx={{fontSize: 16}}/>
                                            <Tab value={'talla'} label={'SIZE'} sx={{fontSize: 16}}/>

                                        </Tabs>
                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                        {getComponente(seleccionada)}

                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={3}
                    >

                        {recursos.map((item, index) => {
                            return (
                                <Grid item container lg={2} sm={3} xs={6} sx={{justifyContent: 'flex-start'}}>
                                    <Tarjeta_Producto_Img_Video item={item} play={false}/>
                                </Grid>
                            )

                        })}


                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default Portada_Detalle

const items = [1, 2, 3]