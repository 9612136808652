/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import BarraPc from "./BarraPc";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import BarraCell from "./BarraCell";

const Barra = () => {
    const {masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            {masSM ?
                <BarraPc/>
                :
                <BarraCell/>
            }

        </Grid>
    )

}
export default Barra    