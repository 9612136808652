import {create} from "zustand";

export const useFuncionesCarrito = create((set, get) => {


    const verificarProductoCarrito = (e) => {

        let products = get().productos
        let index = products.findIndex((it) => it.id === e.id)

        if (index >= 0) {
            return true
        } else {
            return false
        }


    }

    const quitarProducto = (e) => {

        let products = get().productos
        let index = products.findIndex((it) => it.id === e.id)

        products.splice(index, 1)

        set(() => ({productos: products}))

    }




    return {
        productos: [],
        adicionarProducto: (prod) => set((estado) => ({productos: estado.productos.concat(prod)})),
        verificarProductoCarrito,
        quitarProducto

    }
})