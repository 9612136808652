/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Barra from "./Barra/Barra";
import Home from "./Secciones/Home";
import Footer from "./Footer";
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";
import Seccion_Tiendas from "./Secciones/Seccion_Tiendas";
import Seccion_Disenadora from "./Secciones/Seccion_Disenadora";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Seccion_Detalle from "./Secciones/Seccion_Detalle";
import Seccion_Pagos from "./Secciones/Seccion_Pagos";


const Pagina = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Router>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', zIndex: 2, marginBottom: -2}}>
                    <Barra/>
                </Grid>

                <Routes style={{zIndex: 1}}>

                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/Home"} element={<Home/>}/>
                    <Route path={"/Home/:id"} element={<Home/>}/>
                    <Route path={"/*"} element={<Home/>}/>
                    <Route path={"/Stores"} element={<Seccion_Tiendas/>}/>
                    <Route path={"/Catalogue"} element={<Seccion_Catalogo/>}/>
                    <Route path={"/Catalogue/:id"} element={<Seccion_Catalogo/>}/>
                    <Route path={"/Sarasty"} element={<Seccion_Disenadora/>}/>
                    <Route path={"/Detalle/:id"} element={<Seccion_Detalle/>}/>
                    <Route path={"/Payments/:id"} element={<Seccion_Pagos/>}/>
                    <Route path={"/Payments"} element={<Seccion_Pagos/>}/>

                </Routes>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 8}}>
                    <Footer/>
                </Grid>

            </Router>

        </Grid>
    )

}
export default Pagina