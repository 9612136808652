/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, IconButton, Typography} from "@mui/material";
import logo from '../Recursos/logo_blanco.svg'
import iconos from '../Recursos/iconos_footer.png'
import {CONTACTOS, POLITICAS, REDES, SECCIONES} from "../Constantes";
import {useNavigate} from "react-router-dom";
import {useResponsive} from "../Modulo_responsive/Hooks/useResponsive";
import {irUrl} from "../Utilidades/irUrl";

const Footer = () => {
    const navigate = useNavigate()
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#000000'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, pt: 14, pb: 6}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginBottom: 8}}>
                            <img src={logo} width={200} height={'auto'}/>
                        </Grid>

                        <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 20, fontWeight: 600, color: '#fff'}}>
                                        Quick Links
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {SECCIONES.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{justifyContent: 'center', marginTop: 1}}>
                                                    <ButtonBase
                                                        onClick={() => navigate('/' + item)}
                                                        sx={{
                                                            borderBottom: 1,
                                                            borderColor: '#ffffff00',
                                                            borderRadius: 0,
                                                            '&:hover': {borderColor: '#fff'}
                                                        }}
                                                    >
                                                        <Typography sx={{
                                                            color: '#fff',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>{item}</Typography>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={4} sm={4} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 20, fontWeight: 600, color: '#fff'}}>
                                        Contacts
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {CONTACTOS.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{justifyContent: 'center', marginTop: 1}}>
                                                    <ButtonBase>
                                                        <Typography sx={{
                                                            color: '#fff',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>{item}</Typography>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={4} sm={4} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 4 : 0}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography sx={{fontSize: 20, fontWeight: 600, color: '#fff'}}>
                                        Policies
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >

                                        {POLITICAS.map((item, index) => {
                                            return (
                                                <Grid item container lg={12} sm={12} xs={12}
                                                      sx={{justifyContent: 'center', marginTop: 1}}>
                                                    <ButtonBase
                                                        //onClick={() => navigate('/' + item)}
                                                        sx={{
                                                            borderBottom: 1,
                                                            borderColor: '#ffffff00',
                                                            borderRadius: 0,
                                                            '&:hover': {borderColor: '#fff'}
                                                        }}
                                                    >
                                                        <Typography sx={{
                                                            color: '#fff',
                                                            fontSize: 16,
                                                            fontWeight: 400
                                                        }}>{item}</Typography>
                                                    </ButtonBase>
                                                </Grid>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: 8, marginBottom: 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="flex-start"
                            >

                                {REDES.map((item, index) => {
                                    return (
                                        <Grid item container lg={1} sm={1} xs={2} sx={{justifyContent: 'flex-start'}}>
                                            <IconButton onClick={() => irUrl(item.url)}>
                                                <item.icono color={'secondary'} sx={{width: 40, height: 40}}/>
                                            </IconButton>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-start', pr: sCell ? 4 : 0}}>
                            <img src={iconos} width={300} height={'auto'}/>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12}
                              sx={{justifyContent: sCell ? 'center' : 'flex-end', marginTop: 7}}>
                            <Typography sx={{fontSize: 16, color: '#fff', fontWeight: 400}}>
                                ©All rights reserved 2024 | 2025
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Footer

