/**************************************************
 * Nombre:       Portada_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid, TextField, Typography} from "@mui/material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useEffect, useState} from "react";
import Datos_Pago from "./SubComponentes/Datos_Pago";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../../fire";
import {obtenerColeccionQuery} from "../../../Servicios/BD/obtenerColeccionQuery";
import {useLoaders} from "../../../Modulo_Loaders/Hooks/useLoaders";
import logo from "../../../Recursos/logo_blanco.svg";
import BotonPagos from "../../../Modulo_Pasarela_Pagos/Componentes/BotonPagos";
import BotonCripto from "../../../Modulo_BotonCripto/Componentes/BotonCripto";
import btc from '../../../Recursos/qr_btc.png'
import {formatoMoneda} from "../../../Utilidades/formatoMoneda";
import {useNavigate} from "react-router-dom";


const Pagos_Detalle = ({item, setCobro}) => {
    const {sCell, masSM} = useResponsive()
    const navigate = useNavigate()
    const [numero, setNumero] = useState('')
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})


    const consultarCobro = () => {

        if (!numero) {
            alert('Por favor ingrese el numero de pedido')
            return
        }

        abrirCargador('Consultando...')
        let q = query(collection(fire, 'cobros'), where('consecutivo', '==', parseInt(numero)))
        obtenerColeccionQuery(q).then((dox) => {


            if (dox.res && dox.data[0]) {
                let cobro = dox.data[0]

                if (cobro.estadoPago === 'Aceptada') {
                    navigate('/Home?id=' + cobro.id)
                } else {
                    setCobro(dox.data[0])
                }


            }

            cerrarCargador()
        })
    }


    useEffect(() => {

        setNumero(item.consecutivo)
    }, [item]);


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography sx={{fontSize: sCell ? 12 : 18, fontWeight: 400}}>
                            {`Orders and Suits`}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography sx={{fontSize: sCell ? 24 : 36, fontWeight: 700, textAlign: 'center'}}>
                            MAKE YOUR PAYMENT HERE
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography sx={{
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: 'center'
                        }}>
                            Enter your order number here or verify that the information is correct.</Typography>
                    </Grid>

                    <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >

                            <Grid item container lg={7} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                                <TextField
                                    value={numero}
                                    onChange={(e) => setNumero(e.target.value)}
                                    variant={'outlined'} label={"Order number"}/>
                            </Grid>

                            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-start'}}>
                                <Button
                                    onClick={() => consultarCobro()}
                                    color={'primary'} variant={'outlined'}>Search</Button>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                        <Datos_Pago item={item}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginTop: 4}}>
                        <BotonPagos valor={item.valor} idPedido={item.id}
                                    descripcion={`Numero de Pedido ${item.consecutivo}`} cobro={true}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                        <Divider sx={{width: '100%'}}><Typography sx={{color: '#000'}}>OR</Typography></Divider>
                    </Grid>



                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginTop: 2}}>
                            <BotonCripto idPedido={item.id} valor={item.valor}/>
                        </Grid>



                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>

                        <Divider sx={{width: '100%'}}><Typography sx={{color: '#000'}}>OR</Typography></Divider>
                    </Grid>


                    <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                        <img src={btc} width={'50%'} height={'auto'}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                        <Typography sx={{color: '#000'}}>
                            SCAN AND PAY WITH BITCOIN <span
                            style={{fontWeight: 600}}>{`${formatoMoneda(item.valor, 2)} USD`}</span> </Typography>
                    </Grid>


                </Grid>
            </Grid>

        </Grid>
    )

}
export default Pagos_Detalle

