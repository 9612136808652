export const CRIPTOS = [
    {
        nombre: 'ETH - ETHEREUM',
        red: 'ETHEREUM',
        token: 'ETH',
        llave: '0x8b82EEba75A311157333340BB0e6d8dbA71b3d9F'

    },
    {
        nombre: 'USDC - ETHEREUM',
        red: 'ETHEREUM',
        token: 'USDC',
        llave: '0x8b82EEba75A311157333340BB0e6d8dbA71b3d9F'

    },
    {
        nombre: 'SOL - SOLANA',
        red: 'SOLANA',
        token: 'SOL',
        llave: 'HtZW3qJvw6u7Bxj2diy3QEQo428gAYCxKPcA9hgyjUKp'

    },
    {
        nombre: 'USDT - SOLANA',
        red: 'SOLANA',
        token: 'USDT',
        llave: 'HtZW3qJvw6u7Bxj2diy3QEQo428gAYCxKPcA9hgyjUKp'

    },
    {
        nombre: 'MATIC - POLYGON',
        red: 'POLYGON',
        token: 'MATIC',
        llave: '0x8b82EEba75A311157333340BB0e6d8dbA71b3d9F'


    },

]

/*
   {
        nombre: 'BNB - BINANCE',
        red: 'BSC',
        token: 'BNB',

    },
    {
        nombre: 'BNB - BINANCE',
        red: 'BSC',
        token: 'BUSD'

    },
    {
        nombre: 'SOL - SOLANA',
        red: 'SOLANA',
        token: 'USDC'

    },
    {
        nombre: 'USDT - SOLANA',
        red: 'SOLANA',
        token: 'USDT'

    },
    {
        nombre: 'ETH - BASE HETHEREUM',
        red: 'BASE',
        token: 'ETH'

    },
* */


