/**************************************************
 * Nombre:       ColeccionHome
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import ListaProductos from "./SubComponentes/ListaProductos";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useEffect, useState} from "react";
import {obtenerColeccionQuery} from "../../../Servicios/BD/obtenerColeccionQuery";
import {query, where, collection} from "firebase/firestore";
import {fire} from "../../../fire";
import {useNavigate} from "react-router-dom";

const ColeccionHome = ({coleccion = null, difiere = true}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()
    const [productos, setProductos] = useState([])

    useEffect(() => {
        if (coleccion) {
            let q = query(collection(fire, 'productos'), where('coleccion', '==', coleccion))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res) {
                    setProductos(dox.data)
                }
            })
        }
    }, [coleccion]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{fontSize: sCell ? 12 : 18, fontWeight: 400}}>
                                Collection
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: -1}}>
                            <Typography sx={{fontSize: sCell ? 24 : 36, fontWeight: 700}}>
                                {coleccion && coleccion.toUpperCase()}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={11} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 7 : 8}}>
                    <ListaProductos items={productos}/>
                </Grid>

                {!difiere &&
                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'center', marginTop: sCell ? 8 : 12}}>

                        <Button
                            onClick={() => navigate('/Catalogo/' + coleccion)}
                            color={'primary'} sx={{px: sCell ? 4 : 6, fontSize: sCell ? 14 : 16}}>Ver coleccion</Button>
                    </Grid>
                }

            </Grid>

        </Grid>
    )

}
export default ColeccionHome