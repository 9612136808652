/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Portada from "./Componentes/Portada";
import ColeccionHome from "./Componentes/ColeccionHome";
import Tarjeta_Inspiracion from "../Tarjetas/Tarjeta_Inspiracion";
import DisenadoraHome from "./Componentes/DisenadoraHome";
import TipoTela from "./Componentes/TipoTela";
import FraseHumanizadora from "./Componentes/FraseHumanizadora";
import TrajesFormales from "./Componentes/TrajesFormales";
import Portada_Catalogo from "./Componentes/Portada_Catalogo";
import {Gite} from "@mui/icons-material";
import Coleccion_Portada from "./Componentes/Coleccion_Portada";
import Colecciones_Anteriores_Portada from "./Componentes/Colecciones_Anteriores_Portada";
import Portada_Tiendas from "./Componentes/Portada_Tiendas";
import Mapa_Tiendas from "./Componentes/Mapa_Tiendas";
import Portada_Disenadora from "./Componentes/Portada_Disenadora";
import Tarjeta_Tela from "../Tarjetas/Tarjeta_Tela";
import Noticias_Disenadora from "./Componentes/Noticias_Disenadora";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import Coleciones_Disenadora from "./Componentes/Coleciones_Disenadora";
import {useEffect} from "react";
import {irArriba} from "../../Utilidades/irArriba";

const Seccion_Disenadora = () => {
    const {sCell} = useResponsive()

    useEffect(() => {

        irArriba()
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Portada_Disenadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <Coleciones_Disenadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? 8 : 12}}>
                <Tarjeta_Tela/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Noticias_Disenadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <FraseHumanizadora/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 6}}>
                <TrajesFormales/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Disenadora