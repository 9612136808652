import {DIVIPOLA} from "../Constantes";

export const obtenerDepartamentos = () => {

    let arr = DIVIPOLA.map((it) => it.NOMBRE_DEPTO)

    let set = new Set(arr)

    let result = [...set]

    return result

}