/**************************************************
 * Nombre:       Coleciones_Disenadora
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import Tarjeta_Inspiracion from "../../Tarjetas/Tarjeta_Inspiracion";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";

const Coleciones_Disenadora = () => {
    const [coleciones, setColecciones] = useState([])

    useEffect(() => {
        obtenerCol('colecciones').then((dox) => {
            if (dox.res) {
                setColecciones(dox.data)
            }
        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            {coleciones.map((item, index) => {
                return (
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 16}}>
                        <Tarjeta_Inspiracion item={item} difiere={true}/>
                    </Grid>
                )
            })}

        </Grid>
    )

}
export default Coleciones_Disenadora