/**************************************************
 * Nombre:       BarraPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Typography} from "@mui/material";
import {SECCIONES, SECCIONESADICIONALES} from "../../Constantes";
import logo from "../../Recursos/logo_blanco.svg";
import {ShoppingCart} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {obtenerSeccionUrl} from "../../Utilidades/obtenerSeccionUrl";
import {scrolearAComponente} from "../../Utilidades/scrolearAComponente";
import {useCarritoCompras} from "../../Modulo_carrito_compras/Hooks/useCarritoCompras";

const BarraPc = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [isDetalle, setIsDetalle] = useState(false)
    const {BotonCarrito} = useCarritoCompras()

    const clickAdicionales = (id) => {
        if (SECCIONESADICIONALES[0] === id) {
            navigate('/Home')
            setTimeout(() => {
                scrolearAComponente('trajes')
            }, 500)
        } else {
            navigate('/Payments')
        }
    }

    useEffect(() => {

        if (location) {
            setIsDetalle(obtenerSeccionUrl({
                path: location.pathname,
                index: 1
            }) === 'Detalle' || obtenerSeccionUrl({path: location.pathname, index: 1}) === 'Payments')
        }

    }, [location]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, marginBottom: -16}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', py: 3}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            backgroundColor: isDetalle ? '#000' : '#FFFFFF30',
                            border: 1,
                            borderColor: '#FFFFFF40',
                            px: 6,
                            py: 3,
                            backdropFilter: 'blur(6px)',
                            borderTopLeftRadius: 20,
                            borderBottomRightRadius: 20
                        }}
                    >


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start"

                            >

                                {SECCIONES.map((item, index) => {
                                    return (
                                        <ButtonBase
                                            onClick={() => navigate('/' + item)}
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: '#ffffff00',
                                                borderRadius: 0,
                                                '&:hover': {borderColor: '#fff'}
                                            }}>
                                            <Typography
                                                sx={{
                                                    color: '#fff',
                                                    fontWeight: 700,
                                                    fontSize: 13
                                                }}>{item.toUpperCase()}</Typography>
                                        </ButtonBase>
                                    )
                                })}

                            </Grid>
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <img src={logo} width={180} height={'auto'} style={{cursor: 'pointer'}}
                                 onClick={() => navigate('/Home')}
                            />
                        </Grid>

                        <Grid item container lg={1} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}/>

                        <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"

                            >

                                {SECCIONESADICIONALES.map((item, index) => {
                                    return (
                                        <ButtonBase
                                            onClick={() => clickAdicionales(item)}
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: '#ffffff00',
                                                borderRadius: 0,
                                                '&:hover': {borderColor: '#fff'}
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#fff',
                                                    fontWeight: 700,
                                                    fontSize: 13
                                                }}>{item.toUpperCase()}</Typography>
                                        </ButtonBase>
                                    )
                                })}

                                <BotonCarrito/>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default BarraPc    