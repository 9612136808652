import {useEffect, useState} from "react";
import {Dialog, Grid, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import Dialogo_Recibo from "../Dialogos/Dialogo_Recibo";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";

export const useReciboPago = ({logo}) => {
    const [datosRecibo, setDatosRecibo] = useState({})
    const [open, setOpen] = useState(false)
    const [referencia, setReferencia] = useState('')

    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }

    const Dialogo = () => {
        return (
            <Dialog open={open} fullWidth maxWidth={'xs'}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{p: 2}}
                >

                    <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontWeight: 600, color: '#00000080', fontSize: 16}}></Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: 'flex-end', marginTop: 0}}>
                        <IconButton
                            onClick={() => cerrar()}
                        >
                            <Close/>
                        </IconButton>
                    </Grid>


                    <Dialogo_Recibo logo={logo} datos={datosRecibo} referencia={referencia}/>

                </Grid>

            </Dialog>
        )
    }

    function getQueryVariable(variable) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    }

    const getDatos = (ref) => {

        fetch('https://secure.epayco.co/validation/v1/reference/' + ref)
            .then(async (response) => {
                const data = await response.json();
                console.log(data.data)
                setDatosRecibo(data.data)
                abrir()

            })
            .catch((error) => {
                console.error('There was an error!', error);
            });

    };

    useEffect(() => {


        let ref = getQueryVariable('ref_payco');
        // console.log(ref)
        if (ref !== false && ref !== 'undefined') {
            setReferencia(ref)
            getDatos(ref)
        }


        let ref2 = getQueryVariable('id');


        if (ref2) {

            obtenerDoc('cobros', ref2).then((dox) => {

                if (dox.res) {
                    let cobro = dox.data

                    if (cobro.referenciaEpayco !== 'CRIPTO') {
                        cobro.x_extra1 = cobro.id
                        cobro.x_extra2 = 'Cobro'
                        cobro.x_cod_response = cobro.estadoPago === 'Aceptada' ? 1 : 15
                        cobro.x_amount_ok = cobro.valor
                        cobro.x_approval_code = cobro.numeroAprobacion ? cobro.numeroAprobacion : cobro.x_approval_code
                        cobro.x_fecha_transaccion = cobro.fechaPago ? cobro.fechaPago : cobro.x_fecha_transaccion
                        cobro.x_ref_payco = cobro.nombreBanco ? cobro.nombreBanco : cobro.x_ref_payco
                        cobro.x_response = cobro.estadoPago
                        cobro.referencia = cobro.id
                        cobro.x_bank_name = cobro.nombreBanco ? cobro.nombreBanco : cobro.x_bank_name
                        cobro.x_customer_email = cobro.correoPago ? cobro.correoPago : cobro.x_customer_email
                    }


                    setDatosRecibo(cobro)
                    console.log('cobros', cobro)
                    abrir()
                }

            })


        }


    }, [])

    return {
        Dialogo
    }

}