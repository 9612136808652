/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import Anuncios from "./SubComponentes/Anuncios";
import fondo from "../../../Recursos/camila_sarasty.png";
import {Gite} from "@mui/icons-material";
import {TIENDAS} from "../../../Constantes";
import Tarjeta_tienda from "../../Tarjetas/Tarjeta_tienda";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Portada_Disenadora = () => {
    const {sCell} = useResponsive()

    return (

        <Grid item container lg={12} sm={12} xs={12}
              sx={{
                  justifyContent: 'center',
                  marginTop: -0,
                  width: '100%',
                  zIndex: 1,
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.2) 40.36%, #000000 100%), url(${fondo});`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: sCell ? 'center' : 'center',
              }}>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 30 : 45}}>
                            <Typography sx={{fontSize: sCell ? 14 : 18, color: '#fff', fontWeight: 400,}}>
                                Biography</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: -1}}>
                            <Typography sx={{
                                fontSize: sCell ? 24 : 38, color: '#fff', fontWeight: 700, lineHeight: 1.1
                            }}>CAMILA SARASTY</Typography>
                        </Grid>

                        <Grid item container lg={8} sm={8} xs={12}
                              sx={{justifyContent: 'flex-start', marginBottom: 10}}>
                            <Typography sx={{fontSize: 14, color: '#fff', fontWeight: 400, textAlign: 'left'}}>
                                Owned by Colombian fashion designer Camila SARASTY, is a luxury handmade multipurpose
                                clothing line. Founded in 2019, Camila SARASTY adopts design elements that stay true to
                                creating authentic multipurpose clothing that celebrates her Colombian roots.
                                <br/>
                                <br/>
                                Camila Sarasty has gained repeated recognition for her work in fashion and has over the
                                years been featured in ELLE, Harpers Bazaar, Marie Claire, The New York Finance, and
                                more. Celebrated as a multipurpose pieces wear brand, popularly recognised for its
                                eccentric and unique take on versatile garments made with recycled textiles, being
                                described as high fashion art.
                                The prints with 0 water digital stamping made by Camila SARASTY are inspired by
                                gemstones symbolising their mysticism and their particular meaning, the bold colors, and
                                modern femininity so that BY SARASTY WOMAN can identify with each design.
                                <br/>
                                <br/>
                                Operating out of Colombia, New York, Puerto Rico and Mexico the brand caters to females
                                across borders who are confident, adventurous, and unafraid of clothing designed with a
                                statement. Since 2019, the brand has focused on celebrating ethnicity with a modern
                                twist in multipurpose pieces.
                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>

    )

}
export default Portada_Disenadora

