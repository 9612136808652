/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Portada_Catalogo = ({coleccion}) => {
    const {sCell} = useResponsive()

    return (

        <Grid item container lg={12} sm={12} xs={12}
              sx={{
                  justifyContent: 'center',
                  marginTop: -0,
                  width: '100%',
                  zIndex: 1,
                  backgroundImage: `linear-gradient(90deg, #000000 30.05%, rgba(0, 0, 0, 0.13) 69.72%),
                  url(${coleccion.img});`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
              }}>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 30 : 54}}>
                            <Typography
                                sx={{fontSize: sCell ? 14 : 18, color: '#fff', fontWeight: 400, lineHeight: 1.1}}>
                                Colection
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                            <Typography sx={{
                                fontSize: sCell ? 24 : 42, color: '#fff', fontWeight: 700,
                            }}>{coleccion.nombre && coleccion.nombre.toUpperCase()}</Typography>
                        </Grid>

                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontSize: 14, color: '#fff', fontWeight: 400,}}>
                                {coleccion.parrafoUno}
                            </Typography>
                        </Grid>

                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>

                        </Grid>

                        {coleccion && coleccion.lista && coleccion.lista.map((it) => {
                            return (
                                <Grid item container lg={4} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                    <Typography sx={{fontSize: 14, color: '#fff', fontWeight: 400,}}>
                                        {it}
                                    </Typography>
                                </Grid>
                            )
                        })}


                        <Grid item container lg={6} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginBottom: sCell ? 6 : 10, marginTop: 2}}>
                            {coleccion.parrafoDos &&
                                <Typography sx={{fontSize: 14, color: '#fff', fontWeight: 400,}}>
                                    {coleccion.parrafoDos}
                                </Typography>
                            }
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>

    )

}
export default Portada_Catalogo