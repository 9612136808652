/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import fondo from "../../../Recursos/fondo_tiendas.png";
import {TIENDAS} from "../../../Constantes";
import Tarjeta_tienda from "../../Tarjetas/Tarjeta_tienda";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Portada_Tiendas = () => {
    const {sCell} = useResponsive()

    return (

        <Grid item container lg={12} sm={12} xs={12}
              sx={{
                  justifyContent: 'center',
                  marginTop: -0,
                  width: '100%',
                  zIndex: 1,
                  backgroundImage: sCell ? `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 64.75%), url(${fondo})` :
                      `linear-gradient(180deg, rgba(0, 0, 0, 0) 50.29%, #000000 90.69%),url(${fondo});`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
              }}>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        <Grid item container lg={5} sm={5} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 35 : 65}}>
                                    <Typography sx={{
                                        fontSize: sCell ? 14 : 18,
                                        color: '#fff',
                                        fontWeight: 400,

                                    }}>
                                        Locations
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginTop: 0}}>
                                    <Typography sx={{
                                        fontSize: sCell ? 24 : 42, color: '#fff', fontWeight: 700, lineHeight: 1.0
                                    }}>
                                        Sustainable clothing around the world
                                    </Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: 'flex-start', marginBottom: sCell ? 0 : 10, marginTop: 1}}>
                                    <Typography sx={{fontSize: 14, color: '#fff', fontWeight: 400,}}>
                                        Luxury hand made collections manufactured in Colombia operating out of New York,
                                        Puerto Rico and Mexico. Our brand reaches out to women worldwide who exude
                                        confidence, embrace adventure, and boldly wear statement-making versatile
                                        designs.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item container lg={7} sm={7} xs={12}
                              sx={{
                                  justifyContent: 'flex-start',
                                  marginTop: sCell ? 2 : 58,
                                  marginBottom: sCell ? 4 : 0
                              }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={4}
                            >

                                {TIENDAS.map((item, index) => {
                                    return (
                                        <Grid item container lg={3} sm={6} xs={6}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Tarjeta_tienda item={item}/>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>


        </Grid>

    )

}
export default Portada_Tiendas

