/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import bysarasty from "../../../Recursos/bysarasty.mp4";
import bysarasty_cell from "../../../Recursos/bysarasty_cell.mp4";
import Anuncios from "./SubComponentes/Anuncios";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Portada = () => {
    const {sCell, sTab} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            <Grid item container lg={12} sm={12} xs={12}
                  sx={{
                      justifyContent: 'flex-start',
                      marginTop: -0,
                      filter: 'brightness(0.7)',
                      width: '100%',
                      zIndex: 1
                  }}>
                <video style={{width: '100%'}} autoPlay loop muted>
                    <source src={sCell ? bysarasty_cell : bysarasty} type='video/mp4'/>
                </video>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-start', marginTop: sCell ? -45 : sTab ? -40 : -60, zIndex: 2}}>
                <Anuncios/>
            </Grid>


        </Grid>
    )

}
export default Portada