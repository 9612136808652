/**************************************************
 * Nombre:       ListaProductos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import Tarjeta_Producto from "../Tarjetas/Tarjeta_Producto";
import {theme} from "../../Tema";

const ListaProductos = ({productos}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            sx={{px: sCell ? 0 : 2}}
        >

            {productos.map((item, index) => {
                return (
                    <Grid key={`sd${index}`} item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start'}}>
                        <Tarjeta_Producto item={item}/>
                    </Grid>
                )
            })}


        </Grid>
    )

}
export default ListaProductos