/**************************************************
 * Nombre:       Anuncios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import lleno from '../../../../Recursos/ic_circulo_lleno.svg'
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import {useEffect, useRef, useState} from "react";
import Tarjeta_Anuncios from "../../../Tarjetas/Tarjeta_Anuncios";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";
import {obtenerCol} from "../../../../Servicios/BD/obtenerCol";

const Anuncios = () => {
    const refe = useRef(null)
    const {sCell, sTab} = useResponsive()
    const [paso, setPaso] = useState(0)
    const [anuncios, setAnuncios] = useState([])


    const siguiente = () => {
        if (refe.current) {
            let pasoActual = paso
            let pasoSig = pasoActual + 1
            if (pasoActual >= anuncios.length) {
                pasoSig = 0
            }


            refe.current.go(pasoSig);
            setPaso(pasoSig)


        }
    }


    useEffect(() => {
        obtenerCol('anuncios').then((dox) => {
            if (dox.res) {
                setAnuncios(dox.data)
            }
        })
    }, []);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        {!sTab &&

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={3}
                                >

                                    {anuncios.map((item, index) => {
                                        return (
                                            <Grid item container lg={12} sm={12} xs={12}
                                                  sx={{justifyContent: 'flex-start'}}>
                                                <img src={lleno} width={10} height={10}/>
                                            </Grid>
                                        )
                                    })}

                                </Grid>
                            </Grid>
                        }


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 12 : 4}}>
                            <Splide
                                ref={refe}
                                aria-label="My Favorite Images" style={{width: '100%'}}
                                options={{
                                    autoplay: true,
                                    speed: 1500,
                                    rewind: true,
                                    perPage: 1,
                                    arrows: false,
                                    pagination: false,
                                    width: '100%',
                                    drag: 'free',
                                    interval: 5000,
                                    height: sCell ? 200 : sTab ? 250 : 350,
                                    direction: 'ttb',
                                    noDrag: true,

                                }}
                            >

                                {anuncios.map((item, index) => {
                                    return (
                                        <SplideSlide key={`qe${index}`}>
                                            <Tarjeta_Anuncios item={item}/>
                                        </SplideSlide>
                                    )
                                })}


                            </Splide>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Anuncios

