import t_ny from './Recursos/uno.png'
import t_dos from './Recursos/dos.png'
import t_tres from './Recursos/tres.png'
import t_cuatro from './Recursos/cuatro.png'

import {Facebook, Instagram, Pinterest, WhatsApp, X, YouTube} from "@mui/icons-material";

export const SECCIONES = ['Home', 'Catalogue', 'Stores', 'Sarasty']

export const SECCIONESADICIONALES = ['Formal Suits', 'Payments']

export const CONTACTOS = ['info@sarasty.com']

export const POLITICAS = ['Shipping policies', 'Quality politics', 'Privacy Policy']

export const LINKCALENDY = 'https://calendly.com/bysarasty/bysarasty'


export const TIENDAS = [
    {
        nombre: 'MEXICO',
        img: t_ny,

    },
    {
        nombre: 'NUEVA YORK',
        img: t_dos,

    },
    {
        nombre: 'PUERTO RICO',
        img: t_tres,

    },
    {
        nombre: 'MEXICO TULUM',
        img: t_cuatro,

    },
]

export const REDES = [
    {
        nombre: 'facebook',
        icono: Facebook,
        url: 'https://www.facebook.com/bysarasty'
    },
    {
        nombre: 'instagram',
        icono: Instagram,
        url: 'https://www.instagram.com/bysarasty/'
    },
    {
        nombre: 'whatsapp',
        icono: WhatsApp,
        url: 'https://wa.me/573182530307?text=Hola%20By%20Sarasty'
    },
    {
        nombre: 'youtube',
        icono: YouTube,
        url: 'https://youtube.com/channel/UCb0pUcGamyD46TfI6ZEwbSw'
    },
    {
        nombre: 'pinterest',
        icono: Pinterest,
        url: 'https://pin.it/16yzFXk'
    },
    {
        nombre: 'equis',
        icono: X,
        url: 'https://x.com/camilasarasty?t=mAiwzwK72lnmbtHv3pi0yg&s=09'
    }


]