/**************************************************
 * Nombre:       FraseHumanizadora
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import Tarjeta_Noticias from "../../Tarjetas/Tarjeta_Noticias";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import useRecuperarColleccionListeningToda from "../../../Servicios/BD/useRecuperarColleccionListeningToda";
import {useState} from "react";

const Noticias_Disenadora = ({color = '#000'}) => {
    const {sCell} = useResponsive()
    const [wheres, setWheres] = useState([])
    const {data} = useRecuperarColleccionListeningToda({col: 'noticias', orden: 'fecha', wheres: wheres, direccion: 'desc'})


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 16}}>
                            <Typography sx={{fontSize: sCell ? 14 : 18, color: color, fontWeight: 400,}}>
                                news
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 0}}>
                            <Typography sx={{
                                fontSize: sCell ? 24 : 42,
                                color: color,
                                fontWeight: 700,

                            }}>
                                NEWS ABOUT ME</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : 4}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {data.map((item, index) => {
                                    return (
                                        <Grid item container lg={12} sm={12} xs={12}
                                              sx={{justifyContent: 'flex-start'}}>
                                            <Tarjeta_Noticias item={item}/>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default Noticias_Disenadora

