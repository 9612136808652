/**************************************************
 * Nombre:       Seccion_Detalle
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {useNavigate, useParams} from "react-router-dom";
import {irArriba} from "../../Utilidades/irArriba";
import Pagos_Detalle from "./Componentes/Pagos_Detalle";
import {collection, query, where} from "firebase/firestore";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {fire} from "../../fire";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import {useEscucharDoc} from "../../Servicios/BD/Escuchadores/Hooks/useEscucharDoc";

const Seccion_Pagos = () => {
    const {sCell, masSM} = useResponsive()
    const parms = useParams()
    const navigate = useNavigate()
    const [cobro, setCobro] = useState({})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const {data} = useEscucharDoc({coleccion: 'cobros', id: cobro.id})


    useEffect(() => {

        if (data && data.estadoPago) {
            navigate('/Home?id=' + data.id)
        }

    }, [data])

    useEffect(() => {

        irArriba()

        if (parms && parms.id) {


            abrirCargador('Consultando...')
            let q = query(collection(fire, 'cobros'), where('consecutivo', '==', parseInt(parms.id)))
            obtenerColeccionQuery(q).then((dox) => {

                if (dox.res && dox.data[0]) {
                    let cobro = dox.data[0]

                    if (cobro.estadoPago === 'Aceptada') {
                        navigate('/Home?id=' + data.id)
                    } else {
                        setCobro(dox.data[0])
                    }


                }
                cerrarCargador()
            })

        }
    }, [parms]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, marginTop: masSM ? 24 : 14}}
            >

                <Grid item container lg={12} sm={12} xs={11} sx={{justifyContent: 'center'}}>
                    <Pagos_Detalle item={cobro} setCobro={setCobro}/>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default Seccion_Pagos

