/**************************************************
 * Nombre:       Tarjeta_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {useNavigate} from "react-router-dom";

const Tarjeta_Producto = ({item}) => {
    const {sCell} = useResponsive()
    const navigate = useNavigate()

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{cursor: 'pointer'}}
            onClick={() => navigate('/Detalle/' + item.id)}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{
                transition: 'all .4s ease-in-out',
                justifyContent: 'flex-start',
                backgroundImage: `url(${item.img})`,
                '&:hover': {backgroundImage: `url(${item.imgAtras})`},
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <img src={item.img} width={'100%'} height={'auto'} style={{opacity: 0}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Typography sx={{
                    fontSize: sCell ? 14 : 16,
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    textAlign: 'center',
                    lineHeight: 1.2
                }}>{item.nombre && item.nombre.toUpperCase()}</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 1}}>
                {item.stock ?
                    <Typography
                        sx={{
                            fontSize: sCell ? 14 : 16,
                            fontWeight: 400
                        }}>{`${formatoMoneda(item.valor)} USD`}</Typography>

                    :

                    <Typography
                        sx={{
                            fontSize: sCell ? 14 : 16,
                            fontWeight: 400,
                            fontStyle: 'italic',
                            marginTop: -0.5,
                            color: '#00000080'
                        }}>{`Sold out`}</Typography>
                }

            </Grid>


        </Grid>
    )

}
export default Tarjeta_Producto    