/**************************************************
 * Nombre:       Tarjeta_Producto_Img_Video
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import bysarasty_cell from "../../Recursos/bysarasty_cell.mp4";
import bysarasty from "../../Recursos/bysarasty.mp4";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Tarjeta_Producto_Img_Video = ({item, play=true}) => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{width: '100%'}}
        >

            <Zoom>
            {item.tipo === 'video' ?
                <video style={{width: '100%'}} autoPlay={play} loop muted controls={true}
                >
                    <source src={bysarasty_cell} type='video/mp4'/>
                </video>
                :
                <img src={item.src} width={'100%'} height={'auto'}/>
            }
            </Zoom>

        </Grid>
    )
}

export default Tarjeta_Producto_Img_Video