import {create} from "zustand";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";

export const useCantidadesProducto = create((set, get) => {

    const actualizarCantidades = (prod, cantidad) => {
        let products = get().cantidadesProducto
        let index = products.findIndex((it) => it.id === prod.id)

        let prodProcesado = adaptadorProducto(prod, cantidad)


        if (index >= 0) {
            products[index] = prodProcesado
        } else {
            products.push(prodProcesado)
        }


        let valor = valorTotalObtener(products)


        set(() => ({cantidadesProducto: products, valorTotal: valor}))
    }


    const borrarProducto = (prod) => {

        let products = get().cantidadesProducto
        let index = products.findIndex((it) => it.id === prod.id)

        console.log(index)

        products.splice(index, 1)

        let valor = valorTotalObtener(products)

        set(() => ({cantidadesProducto: products, valorTotal: valor}))


    }

    const cantidadProducto = (prod) => {
        let products = get().cantidadesProducto
        let index = products.findIndex((it) => it.id === prod.id)

        if (index >= 0) {
            return products[index].cantidad
        } else {
            return 1
        }


    }

    const valorTotalObtener = (productos) => {

        let total = 0

        if (productos.length > 0) {

            for (let i = 0; i < productos.length; i++) {
                total = total + (productos[i].valorFinal * productos[i].cantidad)
            }

            return total

        } else {
            return 0
        }


    }

    return {
        cantidadesProducto: [],
        actualizarCantidades,
        valorTotal: 0,
        borrarProducto,
        cantidadProducto
    }
})

const adaptadorProducto = (producto, cantidad) => {

    let valor = producto.rebajado > 0 ? producto.rebajado : producto.valor

    let obj = {
        ...producto,
        valorFinal: valor,
        cantidad: cantidad
    }

    return obj

}