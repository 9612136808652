/**************************************************
 * Nombre:       ListaProductos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import Tarjeta_Producto from "../../../Tarjetas/Tarjeta_Producto";

const ListaProductos = ({items}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={4}
        >

            {items.map((item, index) => {
                return (
                    <Grid item container lg={3} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                        <Tarjeta_Producto item={item}/>
                    </Grid>
                )
            })}


        </Grid>
    )

}
export default ListaProductos

