/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Badge, Dashboard, DashboardOutlined,
    Flag,
    HomeWork,
    Mail,
    PermIdentity,
    PhoneAndroid,
    School, ShortText, Link, Title, QrCode2
} from "@mui/icons-material";


const Formulario_Anuncio = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={QrCode2} nombre={'Discount code'} dato={'nombre'}
                                  requerido={'Discount code is required '}  {...props}
                    />
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_Anuncio