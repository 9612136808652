/**************************************************
 * Nombre:       Mapa_Tiendas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import fondo from "../../../Recursos/fondo_mapas.png";
import mapa from "../../../Recursos/mapa.png";
import mapa_cell from "../../../Recursos/mapa_cell.png";
import FraseHumanizadora from "./FraseHumanizadora";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";

const Mapa_Tiendas = () => {
    const {sCell} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                justifyContent: 'center',
                marginTop: -0,
                width: '100%',
                zIndex: 1,
                backgroundImage: `url(${fondo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? 8 : 16}}>
                    <img src={sCell ? mapa_cell : mapa} width={sCell ? '90%' : '80%'} height={'auto'}/>
                </Grid>

                <Grid item container lg={11} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 0 : 24}}>
                    <FraseHumanizadora color={'#fff'}/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Mapa_Tiendas