/**************************************************
 * Nombre:       Tarjeta_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid, Typography, useMediaQuery} from "@mui/material";
import BotonCantidad from "../SubComponentes/BotonCantidad";
import {theme} from "../../Tema";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {useEffect, useState} from "react";
import {useCantidadesProducto} from "../Hooks/useCantidadesProducto";

const Tarjeta_Producto = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [inicial, setInicial] = useState(0)
    const [cantidad, setCantidad] = useState(0)
    const {actualizarCantidades, cantidadProducto} = useCantidadesProducto()


    useEffect(() => {
        if (cantidad > 0) {
            actualizarCantidades(item, cantidad)
        }

    }, [cantidad])

    useEffect(() => {

        let cant = cantidadProducto(item)
        setCantidad(cant)

    }, [item])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"


        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', mb: 1}}>
                <Divider sx={{width: '100%'}}/>
            </Grid>

            <Grid item container lg={4} sm={3} xs={4}
                  sx={{justifyContent: item.formato === '1:1' ? 'flex-start' : 'center', marginTop: 2}}>
                <img src={item.img}
                     width={'80%'} height={'auto'}/>
            </Grid>

            <Grid item container lg={8} sm={9} xs={8} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{fontSize: 20, fontWeight: 600, color: '#3f3f3f'}}>
                            {item.nombre}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: -0.5}}>
                        <Typography sx={{fontSize: 14, fontWeight: 300, color: '#3f3f3f'}}>
                            {item.coleccion}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: 1, marginBottom: 3}}>
                        <Typography sx={{fontSize: 16, color: '#5d5d5d'}}>
                            {item.rebajado > 0 ? formatoMoneda(item.rebajado) : formatoMoneda(item.valor)}{' USD'}
                        </Typography>
                    </Grid>

                    <Grid item container lg={4} sm={4} xs={5} sx={{justifyContent: 'flex-start', marginTop: 1.5}}>
                        <BotonCantidad cantidad={cantidad} setCantidad={setCantidad} item={item}/>
                    </Grid>

                    <Grid item container lg={8} sm={8} xs={7} sx={{justifyContent: 'flex-end', marginTop: 2, pr: 1}}>
                        <Typography sx={{color: '#232323', fontWeight: 600, fontSize: 20}}>
                            {item.rebajado > 0 ? formatoMoneda(item.rebajado * cantidad) : formatoMoneda(item.valor * cantidad)}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default Tarjeta_Producto    