/**************************************************
 * Nombre:       Tarjeta_Inspiracion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import fondo from '../../../Recursos/camila_sarasty.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const DisenadoraHome = () => {
    const {sCell, sTab, masSM} = useResponsive()
    const navigate = useNavigate()


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                    maxWidth: '1400px',
                    mx: 3,
                    backgroundImage: `linear-gradient(179.55deg, rgba(0, 0, 0, 0) 20.45%, #000000 99.35%),
                  url(${fondo});`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: masSM ? 'left' : 'right',
                    borderTopLeftRadius: 60,
                    borderBottomRightRadius: 60,
                    p: sCell ? 4 : 6
                }}
            >


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: masSM ? 40 : 20}}>
                    <Typography sx={{fontSize: sCell ? 12 : 18, color: '#fff', fontWeight: 400,}}>
                        Designer
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                    <Typography sx={{
                        fontSize: sCell ? 28 : 42,
                        color: '#fff',
                        fontWeight: 700,
                        lineHeight: 1.1
                    }}>CAMILA SARASTY</Typography>
                </Grid>

                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                    <Typography sx={{fontSize: sCell ? 12 : 14, color: '#fff', fontWeight: 400,}}>
                        Camila Sarasty has gained repeated recognition for her work in fashion and has over the years
                        been featured in ELLE, Harpers Bazaar, Marie Claire, The New York Finance, and more. Celebrated
                        as a multipurpose pieces wear brand, popularly recognised for its eccentric and unique take on
                        versatile garments made with recycled textiles, being described as high fashion art.

                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <Button
                        onClick={() => navigate('/Sarasty')}
                        sx={{px: sCell ? 4 : 6, fontSize: sCell ? 14 : 16, py: sCell ? 0.8 : 1.5}}>Ver mas</Button>
                </Grid>

            </Grid>


        </Grid>
    )

}
export default DisenadoraHome