/**************************************************
 * Nombre:       Coleccion_Anterior
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import ListaProductos from "./ListaProductos";
import {useResponsive} from "../../../../Modulo_responsive/Hooks/useResponsive";
import {useEffect, useState} from "react";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../../../fire";
import {obtenerColeccionQuery} from "../../../../Servicios/BD/obtenerColeccionQuery";

const Coleccion_Anterior = ({coleccion}) => {
    const {sCell} = useResponsive()
    const [productos, setProductos] = useState([])

    useEffect(() => {
        if (coleccion && coleccion.nombre) {
            let q = query(collection(fire, 'productos'), where('coleccion', '==', coleccion.nombre))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res) {
                    setProductos(dox.data)
                }
            })
        }
    }, [coleccion]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                    backgroundImage: `linear-gradient(90deg, #FFFFFF 30.22%, rgba(255, 255, 255, 0.43) 59.63%),
                  url(${coleccion.img});`,
                    backgroundRepeat: 'repeat',
                    p: sCell ? 4 : 8
                }}

            >

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: sCell ? 0 : 3}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                        <Typography sx={{fontSize: sCell ? 14 : 18, color: '#000', fontWeight: 400,}}>
                            {coleccion.subtitulo}
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                        <Typography sx={{
                            fontSize: sCell ? 24 : 42,
                            color: '#000',
                            fontWeight: 700,
                            lineHeight: 1.1

                        }}>{coleccion.nombre && coleccion.nombre.toUpperCase()}</Typography>
                    </Grid>

                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                        <Typography sx={{fontSize: 14, color: '#000', fontWeight: 400,}}>
                            {coleccion.parrafoUno}
                        </Typography>
                    </Grid>

                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>

                    </Grid>

                    {coleccion.lista.map((it) => {
                        return (
                            <Grid item container lg={4} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 1}}>
                                <Typography sx={{fontSize: 14, color: '#000', fontWeight: 400,}}>
                                    {it}
                                </Typography>
                            </Grid>
                        )
                    })}


                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 1}}>
                        <Typography sx={{fontSize: 14, color: '#000', fontWeight: 400,}}>
                            {coleccion.parrafoDos}
                        </Typography>
                    </Grid>

                </Grid>

            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >


                <Grid item container lg={11} sm={11} xs={12}
                      sx={{justifyContent: 'flex-start', marginTop: sCell ? 10 : 16}}>
                    <ListaProductos items={productos}/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default Coleccion_Anterior