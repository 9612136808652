/**************************************************
 * Nombre:       ColeccionHome
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {useEffect, useState} from "react";
import Coleccion_Anterior from "./SubComponentes/Coleccion_Anterior";
import {obtenerCol} from "../../../Servicios/BD/obtenerCol";

const Colecciones_Anteriores_Portada = ({coleccion}) => {
    const {sCell} = useResponsive()
    const [colecciones, setColecciones] = useState([])

    useEffect(() => {

        if (coleccion && coleccion.nombre) {
            setColecciones([])
            obtenerCol('colecciones').then((dox) => {
                if (dox.res) {
                    let cols = dox.data
                    for (let i = 0; i < cols.length; i++) {
                        if (cols[i].nombre !== coleccion.nombre) {
                            setColecciones((arr) => arr.concat(cols[i]))
                        }
                    }

                }
            })
        }


    }, [coleccion]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            {colecciones.map((item, index) => {
                return (
                    <Grid key={`as${index}`} item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', marginTop: sCell ? 6 : 12}}>
                        <Coleccion_Anterior coleccion={item}/>
                    </Grid>
                )
            })}

        </Grid>
    )

}
export default Colecciones_Anteriores_Portada