/**************************************************
 * Nombre:       Tarjeta_Inspiracion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import fondo from '../../../Recursos/tela.png'
import {useResponsive} from "../../../Modulo_responsive/Hooks/useResponsive";
import {Gite} from "@mui/icons-material";
import iconos from "../../../Recursos/iconos_footer.png";

const TipoTela = () => {
    const {sCell, masSM} = useResponsive()

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{
                    maxWidth: '1400px',
                    mx: 3,
                    backgroundImage: `linear-gradient(179.55deg, rgba(0, 0, 0, 0) 20.45%, #000000 99.35%),
                  url(${fondo});`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                    borderTopLeftRadius: 60,
                    borderBottomRightRadius: 60,
                    p: sCell ? 4 : 8
                }}
            >


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', marginTop: masSM ? 8 : 4}}>
                    <Typography sx={{fontSize: sCell ? 12 : 18, color: '#fff', fontWeight: 400,}}>
                        Fabric Type
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', marginTop: 0}}>
                    <Typography sx={{
                        fontSize: sCell ? 24 : 42,
                        color: '#fff',
                        fontWeight: 700,
                        textAlign: 'right',
                        lineHeight: 1.1

                    }}>RECYCLED TEXTILE</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', marginTop:0}}>
                    <Typography sx={{fontSize: sCell ? 12 : 18, color: '#fff', fontWeight: 400,}}>
                        from pet bottles
                    </Typography>
                </Grid>

                <Grid item container lg={4} sm={12} xs={12}
                      sx={{justifyContent: 'flex-end', marginTop: 2}}>
                    <Typography sx={{fontSize: sCell ? 12 : 14, color: '#fff', fontWeight: 400, textAlign: 'right'}}>
                        BY SARASTY explores the use of quality tailoring and rich Hi-Tech fabrics made in Colombia:
                        <br/>
                        <br/>
                        • Recycled Textile from Pet bottles
                        <br/>
                        • 50% UV Protection Shield
                        <br/>
                        • Waterproof Shield
                        <br/>
                        • Digital Stamping with 0 water
                        <br/>
                        <br/>
                        An Eco-Friendly brand
                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: sCell ? 'center' : 'flex-end', pr: sCell ? 4 : 0, marginBottom: 4}}>
                    <img src={iconos} width={300} height={'auto'}/>
                </Grid>


            </Grid>


        </Grid>
    )

}
export default TipoTela